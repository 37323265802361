<form
  #internalForm="ngForm"
  novalidate
  [diMarkerWrapper]="model"
  [ngClass]="{ 'alien-form': displayExternalOrganization }"
>
  <div *ngIf="model.automated" class="form-group row justify-content-end">
    <div class="col-sm-4 col-md-3">
      <div class="badge badge-danger w-100">Automated</div>
    </div>
  </div>
  <div class="form-group row" style="align-items: baseline" *ngIf="!model.isRoot && model.parents">
    <!-- Lineage -->
    <label class="col-form-label col-sm-4 col-md-2">Lineage</label>
    <div class="col-sm-8 col-md-7 col-form-label">
      <span *ngFor="let element of organizationLineage; let idx = index; trackBy: trackByIndex">
        <span *ngIf="idx < organizationLineage.length - 1">
          <a
            *ngIf="element.type === 'organization'"
            href="/organization/detail/{{ element.org.root }}/{{ element.org._id }}"
            >{{ element.org.name }}</a
          >
          <span *ngIf="element.type === 'placeholder'">...</span>
          ->
        </span>
        <span *ngIf="idx === organizationLineage.length - 1 && element.type === 'organization'">
          {{ element.org.name }}
        </span>
      </span>
    </div>

    <!-- Status -->
    <label class="col-form-label col-sm-4 col-md-1" *ngIf="!displayExternalOrganization">Status</label>
    <div class="col-sm-4 col-md-2" *ngIf="!displayExternalOrganization">
      <span *ngIf="model._meta?.status === 'IN_PROGRESS'" class="badge badge-info">Draft</span>
      <span *ngIf="model._meta?.status !== 'IN_PROGRESS'" class="badge badge-info">{{ model._meta?.status }}</span>
      <span *ngIf="showCheckedBadge" class="badge badge-info">Checked</span>
    </div>
  </div>

  <!-- Name, Acronym -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Name</label>
    <div class="col-sm-8 col-md-7">
      <input
        type="text"
        class="form-control"
        name="name"
        [(ngModel)]="model.name"
        [readonly]="!isFieldEditable('name')"
        *diMarker="{ name: 'name', entity: formReadonly ? 'noop' : 'organization' }"
        required
      />
    </div>
    <label class="col-form-label col-sm-4 col-md-1">Acronym</label>
    <div class="col-sm-4 col-md-2">
      <input
        type="text"
        class="form-control"
        name="acronym"
        [(ngModel)]="model.acronym"
        *diMarker="{ name: 'acronym', entity: formReadonly ? 'noop' : 'organization' }"
        [readonly]="!isFieldEditable('acronym')"
      />
    </div>
  </div>

  <!-- Alternative Names -->
  <div *diMarker="{ name: 'alternativeNames', entity: formReadonly ? 'noop' : 'organization' }">
    <div class="form-group row" *ngFor="let altName of model.alternativeNames; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2" for="altName-{{ idx }}">Alt. Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            id="altName-{{ idx }}"
            name="altName-{{ idx }}"
            [(ngModel)]="model.alternativeNames[idx]"
            [disabled]="!isFieldEditable('alternativeNames')"
            dirtPasteNormalizer
            validateName
          />
          <div class="input-group-append">
            <!-- Button: Add -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(model.alternativeNames)"
              *ngIf="idx === model.alternativeNames.length - 1 && isFieldEditable('alternativeNames')"
            >
              <i class="fa fa-plus-circle"></i>
            </button>
            <!-- Button: Remove -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="removeFromByIndex(model.alternativeNames, idx)"
              *ngIf="idx > 0 && isFieldEditable('alternativeNames')"
            >
              <i class="fa fa-minus-circle"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Original name, Retired -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Original Name</label>
    <div class="col-sm-8 col-md-7">
      <input
        type="text"
        class="form-control"
        name="originalName"
        [(ngModel)]="model.originalName"
        *diMarker="{ name: 'originalName', entity: formReadonly ? 'noop' : 'organization' }"
        [readonly]="!isFieldEditable('originalName')"
      />
    </div>
    <label class="col-form-label col-sm-4 col-md-1">Retired</label>
    <div class="col-sm-4 col-md-2">
      <ui-switch
        size="small"
        name="retired"
        *diMarker="{ name: 'retired', entity: formReadonly ? 'noop' : 'organization' }"
        [(ngModel)]="model.retired"
        [disabled]="!isFieldEditable('retired')"
      ></ui-switch>
    </div>
  </div>

  <!-- Original language -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Original language</label>
    <div class="col-sm-8 col-md-7">
      <select
        id="originalLanguage"
        class="form-control custom-select"
        name="originalLanguage"
        [(ngModel)]="model.originalLanguage"
        [disabled]="!isFieldEditable('originalLanguage')"
      >
        <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.title }}</option>
      </select>
    </div>
  </div>

  <!-- Type -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Type</label>
    <div class="col-sm-8 col-md-7">
      <select
        id="type"
        class="form-control custom-select"
        name="type"
        [(ngModel)]="model.type"
        *diMarker="{ name: 'type', entity: formReadonly ? 'noop' : 'organization' }"
        [disabled]="!isFieldEditable('type')"
      >
        <option *ngFor="let type of types" [value]="type.code">
          {{ type.title }}
        </option>
      </select>
    </div>
  </div>

  <!-- Websource -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Websource</label>
    <div class="col-sm-8 col-md-10">
      <span *diMarker="{ name: 'websource', entity: formReadonly ? 'noop' : 'organization' }">
        <dirt-link
          [(url)]="model.websource"
          name="websource"
          [form]="internalForm"
          [disabled]="!isFieldEditable('websource')"
        ></dirt-link>
      </span>
    </div>
  </div>

  <!-- Priority (for root) -->
  <div class="form-group row" *ngIf="model.isRoot">
    <label class="col-form-label col-sm-4 col-md-2" name="priority">Priority</label>
    <div class="col-sm-8 col-md-10">
      <ngb-rating name="priority" [(rate)]="model.priority" [readonly]="!isFieldEditable('priority')" max="5">
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
        </ng-template>
      </ngb-rating>
    </div>
  </div>

  <!-- Secondary Parents -->
  <div class="form-group row" *ngIf="!model.isRoot && model.parents">
    <label class="col-form-label col-sm-4 col-md-2">Additional parents</label>
    <div class="col-sm-8 col-md-10 col-form-label">
      <span *diMarker="{ name: 'secondaryParents', entity: formReadonly ? 'noop' : 'organization' }">
        <div *ngIf="(model.parents.slice(1).length || 0) < 1">(none yet)</div>
        <div *ngFor="let p of model.parents.slice(1); let secIdx = index">
          <a
            *ngIf="isAlien(organizationInfo[p])"
            [routerLink]="['/organization/detail', organizationInfo[p].root, organizationInfo[p]._id]"
            rel="noopener"
            target="_blank"
            >{{ organizationInfo[p]?.fullName || organizationInfo[p]?.name || p || '-' }}</a
          >
          <a *ngIf="!isAlien(organizationInfo[p])" class="alink" (click)="organizationLinkClicked.emit({ id: p })">{{
            organizationInfo[p]?.name || p || '-'
          }}</a>
          <span
            class="fa fa-trash-o alink"
            *ngIf="isFieldEditable('parents')"
            (click)="onRemoveSecondaryParent(p, secIdx + 1)"
          ></span>
        </div>
        <div *ngIf="!addParent && isFieldEditable('parents')" class="small-dist">
          <a class="alink" (click)="addParent = true">Add...</a>
        </div>
        <div *ngIf="addParent && isFieldEditable('parents')" class="small-dist">
          <dirt-organization-lookup
            [rootOrganizationId]="model.root || model._id"
            [allowOutsideRoot]="true"
            (organizationChange)="onAddSecondaryParent($event)"
          ></dirt-organization-lookup>
        </div>
      </span>
    </div>
  </div>

  <!-- Addresses -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Addresses</label>
    <div class="col-sm-8 col-md-10 col-form-label">
      <div *ngIf="(model.addresses?.length || 0) < 1 && !model.isRoot">
        <span>Use Parent Address: <input type="checkbox" name="useParentAddress" [(ngModel)]="model.noAddress" /></span>
      </div>
      <div style="display: flex; flex-wrap: wrap">
        <div *ngFor="let a of model.addresses; let aIdx = index" [diMarkerWrapper]="a">
          <dirt-address-tile
            [organizationAddress]="a"
            [address]="addressInfo[a.addressId]"
            [organizationInfo]="organizationInfo"
            [removeButton]="true"
            [review]="!formReadonly"
            (removeClicked)="onRemoveAddress(a.addressId, aIdx)"
            [disabled]="!isFieldEditable('addresses')"
            *diMarker="{ name: 'address', entity: formReadonly ? 'noop' : 'organization' }"
          ></dirt-address-tile>
        </div>
      </div>
      <div *ngIf="canAddAddress()" class="small-dist">
        <a class="alink" (click)="addAddress = true">Add...</a>
      </div>
      <div *ngIf="addAddress && !model.noAddress && isFieldEditable('addresses')" class="small-dist">
        <dirt-address-lookup
          [preferredOrgId]="model._id"
          [transformed]="model.transformed"
          (addressChange)="onAddAddress($event)"
        ></dirt-address-lookup>
        <div class="small-dist">
          <dirt-address-tile
            *ngFor="let a of listOtherParentAddresses()"
            [organizationAddress]="a"
            [address]="addressInfo[a.addressId]"
            [draftColor]="true"
            [addButton]="true"
            [disabled]="true"
            (addClicked)="onAddParentAddress(a)"
          ></dirt-address-tile>
        </div>
        <div class="small-dist"><a class="alink" (click)="onOpenNewAddressDialog()">Create...</a></div>
      </div>
      <div *ngIf="!isValid() && !isAddressValid()" class="alert" style="color: red">
        <span *ngIf="model.isRoot"> Please add an address. </span>
        <span *ngIf="!model.isRoot"> Please add an address or use the parent's address. </span>
      </div>
    </div>
  </div>
  <details *ngIf="model.isRoot && isManager">
    <summary>QC preferences</summary>
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Preferred QC</label>
      <div class="col-sm-8 col-md-10 col-form-label">
        <dirt-single-user-select
          [disabled]="formReadonly"
          [(userId)]="model.preferredQc"
          requiredRole="ORGANIZATION_REVIEWER"
        ></dirt-single-user-select>
      </div>
    </div>
  </details>
  <details *ngIf="isManager" style="margin-bottom: 20px">
    <summary>Jobs</summary>
    <button class="btn btn-sm btn-secondary" (click)="onJobCreate()">Create job</button>
  </details>
</form>

<!-- Modal: add address -->
<!-- <ng-template #addAddressModal let-d="dismiss">
  <div class="dirt-content-wrapper" tabindex="0">
    <div class="modal-header">
      <h5 class="modal-title">Create new address</h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <dirt-address
          #addAddressForm
          [address]="addressToCreate"
          [additional]="true"
          [withHouseNo]="true"
          [lenientState]="true"
        ></dirt-address>
        <dirt-link
          [(url)]="addressToCreate.websource"
          placeholder="web source, e.g: https://veeva.com"
          name="websource"
        ></dirt-link>
      </div>
      <div class="pull-right" style="margin: 12px 0">
        <button class="btn btn-sm btn-secondary" (click)="d()">Cancel</button>
        <button class="btn btn-sm btn-primary" (click)="onCreateAddress()" [disabled]="!addAddressForm.isValid()">
          Save and add
        </button>
      </div>
    </div>
  </div>
</ng-template> -->
