import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AddressEntity } from '../address-entity';
import { Organization, OrganizationAddress } from '../organization';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationAPI } from '../api.service';
import { ACL } from '../../../shared/acl/acl.service';

@Component({
  selector: 'dirt-address-tile',
  templateUrl: 'address-tile.html',
  styleUrls: ['address-tile.scss'],
})
export class AddressTile {
  @Input()
  address?: AddressEntity;
  @Input()
  organizationAddress?: OrganizationAddress;
  @Input()
  removeButton: boolean = false;
  @Input()
  addButton: boolean = false;
  @Input()
  draftColor: boolean = false;
  @Input()
  disabled: boolean = false;
  @Input()
  review: boolean = false;
  @Input()
  organizationInfo: { [id: string]: Partial<Organization> & { name; addresses? } } = {};

  @Output()
  removeClicked: EventEmitter<any> = new EventEmitter();
  @Output()
  addClicked: EventEmitter<any> = new EventEmitter();

  @ViewChild('addressDetailsModal')
  addressDetailsModal;

  isEditing: boolean = false;

  constructor(private svcModal: NgbModal, private svcOrganization: OrganizationAPI, private svcAcl: ACL) {}

  onDetail() {
    this.isEditing = false;
    this.svcModal.open(this.addressDetailsModal);
  }

  onEdit() {
    this.isEditing = true;
  }

  onSave() {
    if (!this.address) {
      return;
    }

    const payload = <AddressEntity>{
      originalCity: this.address.originalCity,
      originalLanguage: this.address.originalLanguage,
      originalName: this.address.originalName,
      originalAdditionalInfo: this.address.originalAdditionalInfo,
    };

    this.svcOrganization.updateAddress(this.address._id, payload).subscribe((res) => {
      this.isEditing = false;
      this.address.updatedAt = res.res.updatedAt;
      this.address._version = res.res._version;
    });
  }

  isAddressEditable() {
    return (
      this.address &&
      this.svcAcl.hasCredential('organization.update.prop.addresses') &&
      !this.svcAcl.hasCredential('organization.update.readonly.prop.addresses')
    );
  }

  encodeURIComponent(input: string): string {
    return encodeURIComponent(input);
  }

  onCopyAddress() {
    if (this.address?.formatted) {
      navigator.clipboard.writeText(this.address.formatted);
    }
  }
}
