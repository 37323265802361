<section class="dirt-admin-next">
  <div class="jumbotron" *ngIf="profile">
    <div class="user-meta pull-right">
      <ul class="list-unstyled">
        <li>
          {{ profile.app_metadata.roles.join(', ') }}
          <i class="fa fa-user" aria-hidden="true"></i>
        </li>

        <li>
          {{ profile.email }}
          <i class="fa fa-envelope" aria-hidden="true"></i>
        </li>

        <li>
          {{ pools.join(', ') }}
        </li>
      </ul>

      <ng-template [diAcl]="'person.qc.findbyid'" [diAclAnd]="!isProfileReviewer">
        <dirt-find-person-for-compilers></dirt-find-person-for-compilers>
      </ng-template>
      <dirt-find-profile-for-compilers *diAcl="'profile.qc.next'"></dirt-find-profile-for-compilers>
    </div>
    <h1>
      Howdy <strong>{{ userFirstName }}</strong
      >!
    </h1>

    <div class="dirt-admin-next__wrapper">
      <dirt-association-next *diAcl="'association.next'"></dirt-association-next>
      <dirt-profile-next *diAcl="'profile.next'"></dirt-profile-next>
      <dirt-guideline-next *diAcl="'guideline.next'"></dirt-guideline-next>
      <dirt-sentiment-next *diAcl="'sentiment.next'"></dirt-sentiment-next>
      <dirt-profile-video-next *diAcl="'profile.video.next'"></dirt-profile-video-next>

      <dirt-profile-qc-next *diAcl="'profile.qc.next'"></dirt-profile-qc-next>

      <dirt-event-next-hashtag *diAcl="'event.next.hashtag'"></dirt-event-next-hashtag>
      <dirt-association-qc-next
        [isMultipleActivityReviewer]="isMultipleActivityReviewer"
        *diAcl="'association.idqc'"
      ></dirt-association-qc-next>
      <dirt-association-qc-next
        [isMultipleActivityReviewer]="isMultipleActivityReviewer"
        *diAcl="'association.qc'"
      ></dirt-association-qc-next>
      <dirt-guideline-qc-next
        [isMultipleActivityReviewer]="isMultipleActivityReviewer"
        *diAcl="'guideline.idqc'"
      ></dirt-guideline-qc-next>
      <dirt-guideline-qc-next
        [isMultipleActivityReviewer]="isMultipleActivityReviewer"
        *diAcl="'guideline.qc'"
      ></dirt-guideline-qc-next>

      <dirt-jobs-next *diAcl="'job.next'"></dirt-jobs-next>
    </div>
  </div>
</section>
