import { Person } from './person';
import { PersonStatus } from '../shared/constant/status.enum';

export function deleteEmptyKeyFields(person: Person): void {
  // when we add and remove those IDs, we can't save any longer
  if (person.externalIds && person.externalIds.npi === '') {
    delete person.externalIds.npi;
  }
  if (person.externalIds && person.externalIds.openData === '') {
    delete person.externalIds.openData;
  }
}

export function rangeOfNumbers(end: number, start: number) {
  return new Array(end - start + 1)
    .fill(null)
    .map((_, index) => index)
    .reduce((all, value) => {
      all[value + start] = value + start;
      return all;
    }, {}) as { [key: string]: string };
}

export function setOnSubmitValues(person: Person, isUserCompiler: boolean) {
  person['published'] = true;
  person['reviewedAt'] = new Date();

  // Don't overwrite status out
  if (isUserCompiler && ![PersonStatus.DUPLICATE, PersonStatus.ID_OUT].includes(person._meta.status as any)) {
    person._meta.status = PersonStatus.DONE;
  }

  person._meta.claimedUntil = null;
  person._meta.draftUntil = null;
  setProofLinks(person);
}

export function setOnSubmitIdentValues(person: Person, isUserCreator: boolean) {
  person['identified'] = true;
  person['identifiedAt'] = new Date();

  if (isUserCreator && person._meta.status !== PersonStatus.ID_OUT && person._meta.status !== PersonStatus.DUPLICATE) {
    person._meta.status = PersonStatus.READY_FOR_COMPILATION;
  }
  person._meta.claimedUntil = null;
  person._meta.draftUntil = null;
  setProofLinks(person);
}

function setProofLinks(person: Person) {
  const cvLink = person.cvLinks[0];

  if (
    cvLink &&
    (person.affiliations.length > 0 || person.affiliationsLfka.length > 0) &&
    !person.affiliationsLinks[0]
  ) {
    person.affiliationsLinks[0] = cvLink;
  }

  if (cvLink && !!person.degrees?.find((d) => d !== '') && !person.degreeLinks[0]) {
    person.degreeLinks[0] = cvLink;
  }

  if (cvLink && !!person.specialties?.find((s) => s !== '') && !person.specialtyLinks[0]) {
    person.specialtyLinks[0] = cvLink;
  }

  if (cvLink && !!person.emails?.find((s) => s !== '') && !person.emailLinks[0]) {
    person.emailLinks[0] = cvLink;
  }

  if (
    cvLink &&
    (person.affiliations?.find((af) => !!af.position) ||
      person.affiliationsLfka?.find((afLfka) => afLfka.positions.find((pos) => !!pos.position))) &&
    !person.positionLinks[0]
  ) {
    person.positionLinks[0] = cvLink;
  }

  if (cvLink && !person.nameLinks[0]) {
    person.nameLinks[0] = cvLink;
  }

  if (cvLink && person.status && !person.statusLinks[0]) {
    person.statusLinks[0] = cvLink;
  }
}

export function setOnSaveValues(person: Person) {
  person.published = false;

  if (person.qc) {
    const fields = Object.keys(person.qc);
    if (!fields.length) {
      return;
    }
    fields.forEach((field) => {
      if (!person.qc[field] || !person.qc[field].type) {
        return;
      }
      const subFields = person.qc[field].type.map((errorItem) => errorItem.subField).filter((sf) => !!sf);
      if (!subFields.length) {
        return;
      }
      subFields.forEach((subField) => {
        const subFieldErrors = person.qc[field].type.filter((errorItem) => errorItem.subField === subField);
        if (subFieldErrors.length) {
          person.qc[subField] = {
            type: subFieldErrors.map(({ subField, ...rest }) => rest),
          };
        }
      });
      const onlyFieldErrors = person.qc[field].type && person.qc[field].type.filter((item) => !item.subField);
      if (onlyFieldErrors.length > 0) {
        person.qc[field] = {
          type: onlyFieldErrors,
        };
      } else {
        delete person.qc[field];
      }
    });
  }
}
