export enum PersonJob {
  VID = 'VID',
  VID_QC = 'VID_QC',
  PERSON_IMAGE_CURATION = 'PERSON_IMAGE_CURATION',
  PERSON_IMAGE_MAINTENANCE = 'PERSON_IMAGE_MAINTENANCE',
  LFKA_KPP_CURATION = 'LFKA_KPP_CURATION',
  LFKA_KPP_MAINTENANCE = 'LFKA_KPP_MAINTENANCE',
  DEDUPLICATION = 'DEDUPLICATION',
  EDUCATIONAL_BACKGROUND = 'EDUCATIONAL_BACKGROUND',
  PERSON_LINKEDIN_CURATION = 'PERSON_LINKEDIN_CURATION',
  PERSON_LINKEDIN_QC = 'PERSON_LINKEDIN_QC',
  ORGANIZATION_DISCONNECTION = 'PERSON_ORGANIZATION_DISCONNECTION',
  EDUCATION = 'EDUCATION',
  EDUCATION_QC = 'EDUCATION_QC',
  WORK = 'WORK',
  WORK_QC = 'WORK_QC',
  WORK_MAINTENANCE = 'WORK_MAINTENANCE',
  WORK_MAINTENANCE_QC = 'WORK_MAINTENANCE_QC',
  /** Equivalent to DEDUPLICATION + WORK + EDUCATION. Once submitted, the person status is set to DONE */
  BASE_DATA = 'BASE_DATA',
  BASE_DATA_QC = 'BASE_DATA_QC',
  PERSON_SO_QC = 'PERSON_SO_QC',
  CT_AFFILIATION = 'CT_AFFILIATION',
  CT_AFFILIATION_QC = 'CT_AFFILIATION_QC',
}
