import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InternalDuplicatesResponse } from '../api.service';
import { Job } from '../../../jobs/shared/job';
import { OrganizationSubTree } from '../types/sub-tree';

export type ExpandedEvent = { id: string; expanded: boolean };
export type SelectedEvent = { id: string; selected: boolean };

@Component({
  selector: 'dirt-organization-tree-part',
  templateUrl: 'tree-part.component.html',
  styleUrls: ['tree-part.component.scss'],
})
export class OrganizationTreePartComponent {
  @Input()
  leaf: OrganizationSubTree;

  @Input()
  /** ID of the parent leaf in the tree */
  parentId?: string;

  @Input()
  expandedMap: { [id: string]: boolean };
  @Input()
  internalDuplicatesMap: InternalDuplicatesResponse['duplicates'];
  @Input()
  colorMap: Record<string, string>;
  @Input()
  multiSelectMap: { [id: string]: boolean };
  @Input()
  singleSelectHolder: { id: string }; // (can change the ID on the fly)
  @Input()
  currentJob?: Job = null;
  @Input()
  showAdditionalTypeInformation: boolean = false;

  @Output()
  toggleExpanded: EventEmitter<ExpandedEvent> = new EventEmitter(); // when expanded, load children
  @Output()
  toggleMultiselect: EventEmitter<SelectedEvent> = new EventEmitter();
  @Output()
  changeSingleSelect: EventEmitter<{ id: string }> = new EventEmitter();
  @Output()
  jumpToOrg: EventEmitter<{ id: string }> = new EventEmitter();

  private readonly typesMap: { [code: string]: string } = {};

  doToggleExpanded() {
    if (!this.expandedMap) {
      return console.warn('No expandedMap');
    }
    this.expandedMap[this.leaf._id] = !this.expandedMap[this.leaf._id];
    this.toggleExpanded.emit({ id: this.leaf._id, expanded: this.expandedMap[this.leaf._id] });
  }
  doToggleMultiselect() {
    if (!this.multiSelectMap) {
      return console.warn('No multiSelectMap');
    }
    this.multiSelectMap[this.leaf._id] = !this.multiSelectMap[this.leaf._id];
    this.toggleMultiselect.emit({ id: this.leaf._id, selected: this.multiSelectMap[this.leaf._id] });
  }
  doSingleSelect() {
    if (!this.singleSelectHolder) {
      return console.warn('No singleSelectHolder');
    }
    this.singleSelectHolder.id = this.leaf._id;
    this.changeSingleSelect.emit(this.singleSelectHolder);
  }

  childrenEntries(sort: boolean = true) {
    if (sort) {
      return this.leaf.children
        .slice()
        .sort((c1, c2) => (this.getTitle(c1) || '').localeCompare(this.getTitle(c2) || ''));
    }

    return this.leaf.children;
  }

  getTitle(org: Pick<OrganizationSubTree, 'name' | 'type'>) {
    if (this.showAdditionalTypeInformation) {
      return org.name + ' (' + (this.typesMap[org.type] || org.type || '-') + ')';
    }
    return org.name;
  }

  tracker(index: number, item: { _id: string }): string {
    // don't redo subtrees when we can help it
    return item._id;
  }

  shortDisplayName(org: Pick<OrganizationSubTree, 'name' | 'type'>): string {
    // match colors again
    const longDisplayName = this.getTitle(org);
    if (longDisplayName && longDisplayName.indexOf(' (') > 0) {
      return longDisplayName.substring(0, longDisplayName.indexOf(' ('));
    } else {
      return longDisplayName;
    }
  }

  getLeafHint(): string | void {
    if (this.leaf.isAlien) {
      return 'Organization primary parent is not part of the tree';
    }

    if (this.isInTreeAlien()) {
      return 'Organization primary parent is located elsewhere in the tree';
    }

    return '';
  }

  isInTreeAlien(): boolean {
    return this.parentId && this.leaf.parents?.[0] !== this.parentId;
  }
}
