import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { groupBy, toPairs, find } from 'lodash';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { FormList } from '../../interfaces/form-list.interface';
import { ACL } from '../../acl/acl.service';
import { AffiliationAPI } from '../../../affiliation/shared/api.service';
import { Value } from '../../services/value/value';
import { ValueType } from '../../enum/value-type.enum';
import { ValueAPI } from '../../services/value/value-api.service';
import { Roles } from '../../../shared/acl/roles';
import { CapitalizePipe } from '../../pipes';
import { PersonAffiliationClinical } from '../../../person/shared/person-affiliation-clinical';
import { NgForm } from '@angular/forms';
import { PersonAffiliation } from '../../../person/shared/person-affiliation';
import { OrganizationAPI } from '../../../organizations/shared/api.service';
import { OrganizationSelectModalService } from '../../../organizations/shared/select-modal/select-modal.service';
import { PersonAffiliationClinicalTakeoverStrategy } from './takeover-strategy.enum';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'dirt-affiliations-clinical',
  templateUrl: 'affiliations-clinical.component.html',
  styleUrls: ['affiliations-clinical.component.scss'],
})
export class PersonAffiliationsClinicalComponent implements OnInit, FormList {
  // mostly similar to dirt-affiliations

  @Input()
  affiliations: Array<PersonAffiliationClinical> = [];

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  @Input()
  mandatoryBecauseOfStatus: boolean = true;

  @Input()
  disabled: boolean;

  @Input()
  reviewDisabled: boolean;

  @Input()
  currentJobType?: string;

  @Input()
  currentQcArea?: string;

  @Input()
  baseAffiliations: Array<PersonAffiliation> = [];

  @Input()
  createRequestHandler: (requestOwnerProduct?: string) => Observable<PersonAffiliationClinical> = null;

  readonly requestOwnerProduct = 'ODC';

  @Input()
  checkFindings: { [field: string]: string[] } = {};

  @Input()
  maintenanceRequestHandler: (affiliation: PersonAffiliationClinical, requestOwnerProduct?: string) => void = null;

  @ViewChild(NgbTypeahead, { static: false })
  searchAutocomplete: NgbTypeahead;

  affiliationsToPromote: PersonAffiliation[] = [];

  expanded = new Set<string>();
  expandedDetail: { [_id: string]: boolean } = {};
  addedByCurrentUser = new Set<string>();
  searchTerm: string;
  isSearching = false;
  searchedOnce = false;
  searchTimeOutForPos: any;

  countryValues: Value[] = [];

  groupedAffiliations: Array<{
    name: string;
    originalName?: string;
    isInactiveAfg?: boolean;
    affiliations?: Array<PersonAffiliationClinical>;
    inactiveAffiliations?: Array<{
      name: string;
      originalName?: string;
      isInactiveAfg?: boolean;
      affiliations?: Array<PersonAffiliationClinical>;
    }>;
  }> = [];

  takeoverStrategy = PersonAffiliationClinicalTakeoverStrategy.REQUESTED_BECOMES_PRIMARY; // Majority of the cases
  affiliationTakeoverStrategy = PersonAffiliationClinicalTakeoverStrategy;

  @ViewChild('takeoverStrategyModal')
  private takeoverStrategyModal: TemplateRef<any>;

  @ViewChild('affiliationsPromotionModal')
  private affiliationsPromotionModal: TemplateRef<any>;

  private inScopeCountries: string[] = [];

  searchAffiliations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcAffiliation.search(term, 100).pipe(
          // tap(() => this.searchFailed = false),
          catchError(() => {
            // this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false)),
      tap(() => (this.searchedOnce = true))
    );

  constructor(
    private readonly svcACL: ACL,
    private readonly svcAffiliation: AffiliationAPI,
    private svcValue: ValueAPI,
    private capitalizePipe: CapitalizePipe,
    private svcOrganizationTreeModal: OrganizationSelectModalService,
    private svcOrganization: OrganizationAPI,
    private svcModal: NgbModal
  ) {}

  ngOnInit() {
    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countryValues = data;
    });

    this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.inScopeCountries = values.map((v) => v.code as string);
    });

    this.expanded.clear();
    this.groupAffiliations();
  }

  hasAfgGroupUnpolished(afg): boolean {
    const affiliations = afg.isInactiveAfg ? afg.inactiveAffiliations : afg.affiliations;
    return affiliations.some((a) => !a.readyForDelivery);
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  isValid(): boolean {
    let isValid = true;

    if (this.disabled) {
      return true;
    }

    // Make sure that all affiliations are from in-scope countries
    if (!this.disabled && this.ngForm.status !== 'DISABLED') {
      isValid &&= (this.affiliations?.filter((a) => this.isOutOfScopeCountry(a))?.length || 0) === 0;
    }

    if (
      this.svcACL.hasCredential('person.update.mandatory.prop.affiliationsClinical', this.currentJobType) &&
      this.mandatoryBecauseOfStatus
    ) {
      isValid &&= this.ngForm.status === 'DISABLED' || this.ngForm.form.valid;
    }

    return isValid;
  }

  isFieldMandatory(field: string): boolean {
    return (
      this.svcACL.hasCredential(`person.update.mandatory.prop.${field}`, this.currentJobType) &&
      this.mandatoryBecauseOfStatus
    );
  }

  getListItems(): PersonAffiliationClinical[] {
    return this.affiliations;
  }

  async onSelect(event: {
    preventDefault: () => void;
    item: PersonAffiliationClinical & { organizationId?: string };
  }): Promise<void> {
    event.preventDefault();

    if (event.item.organizationId) {
      const organization = await firstValueFrom(this.svcOrganization.findById(event.item.organizationId));
      if (organization) {
        // We need to make an explicit choice when we have multiple addresses
        if (organization.addresses?.length > 1) {
          this.onOpenTree(null, event.item.organizationId);
          return;
        } else if (organization.addresses.length) {
          const address = await firstValueFrom(this.svcOrganization.findAddressByIds(organization.addresses[0].id));
          if (address.length > 0) {
            event.item.organizationAddress = address[0];
          }
        }

        event.item.organization = organization;
      }
    }

    if (event.item?.address?.countryCode && !this.inScopeCountries.includes(event.item.address.countryCode)) {
      alert('People must never be actively affiliated with an out-of-scope country organization.');
      return;
    }

    if (
      !event.item.readyForDelivery &&
      !confirm('You are adding an affiliation not polished yet by central organizations. Use anyway?')
    ) {
      return;
    }

    this.addAffiliation(event.item);

    // clean up
    this.searchTerm = '';
    this.searchedOnce = false;
    this.searchAutocomplete.dismissPopup();
  }

  removeAffiliation(e: Event, id: string) {
    e.stopPropagation();

    const idx = this.affiliations.findIndex((af) => af.id === id);

    if (!confirm('Sure to delete the affiliation?')) {
      return;
    }

    this.addedByCurrentUser.delete(this.affiliations[idx].id);
    this.affiliations.splice(idx, 1);

    this.syncPrimary();
    this.groupAffiliations();
  }

  toggleExpanded(name: string): void {
    const isExpanded = this.expanded.has(name);

    isExpanded ? this.expanded.delete(name) : this.expanded.add(name);
  }
  isExpanded(name: string): boolean {
    return this.expanded.has(name);
  }

  showAffiliationModal() {
    this.createRequestHandler('ODC').subscribe(async (result) => {
      if (result.id) {
        await this.addAffiliation(result, true);
      }
    });
  }

  canRemoveItem(affiliation: PersonAffiliationClinical): boolean {
    if (!affiliation.readyForDelivery) {
      return (
        this.svcACL.hasCredential('person.update.prop.affiliations.remove', this.currentJobType) ||
        this.svcACL.hasCredential('person.update.prop.affiliations.removeRequest', this.currentJobType) ||
        this.addedByCurrentUser.has(affiliation.id)
      );
    }

    return (
      this.svcACL.hasCredential('person.update.prop.affiliations.remove', this.currentJobType) ||
      this.addedByCurrentUser.has(affiliation.id)
    );
  }

  canSetInactive(): boolean {
    return this.svcACL.hasCredential('person.update.prop.affiliationsClinical.inactive', this.currentJobType);
  }

  private async addAffiliation(affiliation: PersonAffiliationClinical, isNew?: boolean): Promise<void> {
    if (!affiliation.readyForDelivery) {
      const ref = this.svcModal.open(this.takeoverStrategyModal);
      await firstValueFrom(ref.closed);

      affiliation.takeoverStrategy = this.takeoverStrategy;
      affiliation.primaryCandidate = [
        PersonAffiliationClinicalTakeoverStrategy.REQUESTED_BECOMES_PRIMARY,
        PersonAffiliationClinicalTakeoverStrategy.REQUESTED_BECOMES_PRIMARY_NO_DISABLE,
      ].includes(affiliation.takeoverStrategy as any);
      this.takeoverStrategy = PersonAffiliationClinicalTakeoverStrategy.REQUESTED_BECOMES_PRIMARY; // Reset to default for next
    }

    // Ensure only one primary candidate at a time
    if (affiliation.primaryCandidate) {
      this.affiliations.forEach((existingAffiliation) => {
        if (existingAffiliation.id !== affiliation.id) {
          existingAffiliation.primaryCandidate = false;
        }
      });
    }
    // don't add the same affiliation multiple times
    if (this.affiliations.findIndex((item) => affiliation.id === item.id) < 0) {
      if (isNew) {
        affiliation.newlyCreated = true;
      }
      affiliation.countryCode = affiliation.address?.countryCode;
      affiliation.phone = affiliation.phone || {}; // Initialize phone if it doesn't exist
      affiliation.phone.number = affiliation.phone.number || '';
      affiliation.phone.countryCallingCode = affiliation.phone.countryCallingCode || '';
      affiliation.phone.extension = affiliation.phone.extension || '';
      affiliation.email = '';
      affiliation.emailLink = '';
      this.affiliations.push(affiliation);
      this.addedByCurrentUser.add(affiliation.id);
      affiliation.affiliationsClinicalChanged = true;
    }
    this.syncPrimary();
    this.groupAffiliations();
    this.expanded.add(affiliation.name);
  }

  onCopyAffiliationName(affiliation?: PersonAffiliationClinical): void {
    const value = affiliation.name + ` ${this.capitalizePipe.transform(affiliation.type)}`;
    navigator.clipboard.writeText(value);
  }

  groupAffiliations() {
    this.groupedAffiliations = [
      ...toPairs(
        // array of pairs where each pair contains a 'name' and an array of affiliations with that name
        groupBy(
          // group by active affiliations by `name` and make sure there's an `id` (KS-4919)
          this.affiliations.filter((a) => !a.inactive && a.id),
          'name'
        )
      )
        .map(([name, affiliations]) => ({
          // each pair to create an object containing the name, originalName (if available), and sorted list of affiliations
          name,
          originalName: (find(affiliations, ['name', name]) || { originalName: '' }).originalName,
          affiliations: affiliations.sort(
            (af1, af2) =>
              (!af1.department ? -100 : !af2.department ? 100 : 0) +
              Math.sign((af1.department || '').localeCompare(af2.department || ''))
          ),
        }))
        // sort alphabetically by name
        .sort((afg1, afg2) => Math.sign((afg1.name || '').localeCompare(afg2.name || ''))),
      ...(this.affiliations.filter((a) => a.inactive).length > 0
        ? [
            {
              name: 'Inactive affiliations',
              isInactiveAfg: true,
              inactiveAffiliations: [
                ...toPairs(
                  groupBy(
                    this.affiliations.filter((a) => a.inactive),
                    'name'
                  )
                )
                  // Maps over each pair to create an object containing the name, originalName (if available), and sorted list of inactive affiliations
                  .map(([name, affiliations]) => ({
                    name,
                    originalName: (find(affiliations, ['name', name]) || { originalName: '' }).originalName,
                    affiliations: affiliations.sort(
                      (af1, af2) =>
                        (!af1.department ? -100 : !af2.department ? 100 : 0) +
                        Math.sign((af1.department || '').localeCompare(af2.department || ''))
                    ),
                  })),
              ],
            },
          ]
        : []),
    ];
  }

  isOutOfScopeCountry(affiliation: PersonAffiliationClinical): boolean {
    return !this.inScopeCountries.includes(affiliation.address?.countryCode);
  }

  async copyBaseAffiliations() {
    const ref = this.svcModal.open(this.affiliationsPromotionModal);
    await firstValueFrom(ref.closed);
  }

  isSelected(affiliation: PersonAffiliation): boolean {
    return (
      this.affiliationsToPromote.some((selectedAffiliation) => selectedAffiliation.id === affiliation.id) ||
      this.affiliations.some((existingAffiliation) => existingAffiliation.id === affiliation.id)
    );
  }

  toggleSelection(affiliation: PersonAffiliation, checked: boolean): void {
    if (checked) {
      if (!this.affiliationsToPromote.some((selectedAffiliation) => selectedAffiliation.id === affiliation.id)) {
        this.affiliationsToPromote.push({ ...affiliation });
      }
    } else {
      // Remove from the list of affiliations to promote
      this.affiliationsToPromote = this.affiliationsToPromote.filter(
        (selectedAffiliation) => selectedAffiliation.id !== affiliation.id
      );

      // Remove from clinical affiliations list
      const idx = this.affiliations.findIndex((existingAffiliation) => existingAffiliation.id === affiliation.id);
      if (idx > -1) {
        this.affiliations.splice(idx, 1);

        // Call groupAffiliations to update the UI after modification
        this.groupAffiliations();
      }
    }
  }

  promoteAffiliations() {
    this.affiliationsToPromote.forEach((selectedAffiliation) => {
      const newAffiliation: PersonAffiliationClinical = cloneDeep(selectedAffiliation);

      newAffiliation.phone = {};
      newAffiliation.phoneLink = '';
      newAffiliation.email = '';
      newAffiliation.emailLink = '';
      newAffiliation.inactive = false;

      const exists = this.affiliations.some((existingAffiliation) => existingAffiliation.id === newAffiliation.id);
      if (!exists) {
        //Ensure that an existing primary clinical affiliation remains primary if a promoted affiliation is also marked as primary.
        if (newAffiliation.primary) {
          this.affiliations.forEach((existingAffiliation) => {
            if (existingAffiliation.id !== newAffiliation.id && existingAffiliation.primary) {
              newAffiliation.primary = false;
            }
          });
        }
        this.addAffiliation(newAffiliation);
      }
    });

    this.affiliationsToPromote = [];
  }

  hasPrimaryAffiliation(afg) {
    return afg.affiliations
      ? afg.affiliations.filter((a) => a.primary).length > 0
      : this.inactiveHasPrimaryAffiliation(afg);
  }

  inactiveHasPrimaryAffiliation(afg) {
    return afg.inactiveAffiliations.some((elm) => {
      return elm.affiliations.filter((a) => a.primary).length > 0;
    });
  }

  hasPrimaryCandidateAffiliation(afg): boolean {
    return (
      !!afg.affiliations?.find((a) => a.primaryCandidate) ||
      afg.inactiveAffiliations?.some((elm) => !!elm.affiliations.find((a) => a.primaryCandidate))
    );
  }

  setPrimary(event: Event, id: string): void {
    event.stopPropagation();

    if (this.hasAnyLastKnown()) {
      return;
    }

    const newPrimary = this.affiliations.find((a) => a.id === id);
    if (!newPrimary) {
      return;
    }

    if (!newPrimary.readyForDelivery) {
      // Cannot mark it manually, see takeoverStrategy instead
      return;
    }
    if (!!this.affiliations.find((a) => !a.readyForDelivery)?.primaryCandidate) {
      alert('Cannot change primary affiliation while another affiliation is marked as primary candidate');
      return;
    }

    if (newPrimary.inactive) {
      delete newPrimary.inactive;
    }

    // sync primary state
    this.affiliations.forEach((a) => (a.primary = a.id === newPrimary.id));
  }

  hasAnyLastKnown(): boolean {
    return this.affiliations.filter((_a) => _a.lastKnown).length > 0;
  }

  async onOpenTree(event: MouseEvent = null, organizationId: string): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    try {
      const res = await this.svcOrganizationTreeModal.open(organizationId, {
        mappingSelection: 'REQUIRED',
        addressSelection: 'OPTIONAL',
      });

      const affiliation = (await firstValueFrom(
        this.svcAffiliation.findById(res.affiliationId)
      )) as PersonAffiliationClinical;

      affiliation.organization = res.organization;
      affiliation.organizationAddress = res.address;

      this.onSelect({ preventDefault: () => null, item: affiliation });
    } catch (error) {
      // The modal was closed
      return;
    }
  }

  unsetPrimaryCandidate(id: string): void {
    const affiliation = this.affiliations.find((a) => a.id === id);
    if (!affiliation) {
      return;
    }

    if (
      !confirm(
        'By removing the primary candidate, affiliation will stay as secondary once polished by central ops team. Do you still want to remove the flag?'
      )
    ) {
      return;
    }

    affiliation.primaryCandidate = false;
    affiliation.lastKnownCandidate = false;
    affiliation.takeoverStrategy = PersonAffiliationClinicalTakeoverStrategy.REQUESTED_BECOMES_SECONDARY;
  }

  trackById(idx, item: PersonAffiliationClinical): string {
    return item.id;
  }

  setLastKnown(event: Event, id: string): void {
    event.stopPropagation();

    const affiliation = this.affiliations.find((af) => af.id === id);
    if (!affiliation?.readyForDelivery) {
      // Just to be sure
      return;
    }

    if (!confirm('Sure to set this last known and all other affiliations inactive?')) {
      return;
    }

    this.affiliations.forEach((af) => {
      if (af.id === id) {
        af.primary = true;
        af.lastKnown = true;
        delete af.inactive;
      } else {
        delete af.primary;
        delete af.primaryCandidate;
        delete af.lastKnown;
        af.inactive = true;
      }
    });
    affiliation.affiliationsClinicalChanged = true;
  }

  setLastKnownCandidate(id: string): void {
    const affiliation = this.affiliations.find((af) => af.id === id);

    // Not possible to mark as last known when strategy is not for requested aff to become primary
    if (
      !affiliation?.readyForDelivery &&
      ![
        PersonAffiliationClinicalTakeoverStrategy.REQUESTED_BECOMES_PRIMARY,
        PersonAffiliationClinicalTakeoverStrategy.REQUESTED_BECOMES_PRIMARY_NO_DISABLE,
      ].includes(affiliation.takeoverStrategy as any)
    ) {
      return;
    }

    if (!confirm('Sure to set this last known candidate?')) {
      return;
    }

    this.affiliations.forEach((af) => {
      if (af.id === id) {
        af.primaryCandidate = true;
        af.lastKnownCandidate = true;
        delete af.inactive;
      } else {
        delete af.primaryCandidate;
        delete af.lastKnownCandidate;
        af.inactive = !af.primary; // We shouldn't disable the current primary just yet, we'll take care of that when swapping candidate with current primary depending on the selected strategy
      }
    });
    affiliation.affiliationsClinicalChanged = true;
  }

  unsetLastKnown(event: Event, id: string): void {
    // can always do it
    event.stopPropagation();

    this.affiliations.forEach((af) => {
      if (af.id === id) {
        delete af.lastKnown;
        delete af.lastKnownCandidate;
        af.affiliationsClinicalChanged = true;
      }
    });
  }

  setInactive(event: Event, id: string) {
    event.stopPropagation();
    if (this.hasAnyLastKnown()) {
      return;
    }

    if (!this.canSetInactive()) {
      return;
    }

    const af = this.affiliations.find((o) => o.id === id);

    if (af) {
      af.inactive = !af.inactive;

      if (!af.inactive) {
        delete af.inactive;
      }
    }
    af.affiliationsClinicalChanged = true;
  }

  affiliationsClinicalChange(affiliation: PersonAffiliationClinical) {
    affiliation.affiliationsClinicalChanged = true;
  }

  private syncPrimary(): void {
    const primaryIdx = this.affiliations.findIndex((aff) => aff.primary);

    if (primaryIdx === -1 && this.affiliations.length > 0) {
      const firstItem = this.affiliations[0];
      if (firstItem.readyForDelivery) {
        firstItem.primary = true;
      } else {
        firstItem.primaryCandidate = true;
      }

      // always keep the new primary expanded
      this.expanded.add(firstItem.name);
    }
  }
}
