import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { format } from 'date-fns';
import { firstValueFrom, forkJoin } from 'rxjs';
import { capitalize, cloneDeep, isNil } from 'lodash';

import { Affiliation } from '../shared/affiliation';
import { AffiliationAPI } from '../shared/api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { Value } from '../../shared/services/value/value';
import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';
import { UserGroupAPI } from '../../user-group/shared/api.service';
import { Roles } from '../../shared/acl/roles';
import { ACL } from '../../shared/acl/acl.service';
import { AffiliationStatus } from '../shared/constant/status.enum';
import { UserGroup } from '../../user-group/shared/user-group';
import { AffiliationAdvancedSearchDto } from '../shared/affiliation-advanced-search-dto';

const LS_ADVANCED_SEARCH_KEY = 'clean-affiliations-advanced-search';

@Component({
  selector: 'dirt-affiliation-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class AffiliationListComponent implements OnInit {
  affiliations: Affiliation[];

  isLoading = false;

  total: { count: number };
  pagingPage = 1;
  pagingLimit = 25;
  pagingSkip = 0;

  sort = '-_id';

  filter: { [key: string]: any };
  normalizedFilter: { [key: string]: any };
  filters: Filter[] = [];
  filterBarConfig = {
    'address.countryCode': {
      label: 'Region',
    },
    'address.opState': {
      label: 'State',
    },
    verified: {
      label: 'Verified',
    },
    createdAt: {
      label: 'Created at',
      format: (value) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    source: {
      label: 'Automated',
    },
    readyForDelivery: {
      label: 'Delivery Ready',
    },
    '_meta.status.keyword': {
      label: 'Status',
      format(value: string) {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
  };

  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');

  advancedSearchSpec: AffiliationAdvancedSearchDto = null;
  @ViewChild('advancedSearchDialog', { read: ElementRef }) advancedSearchDialogElement: ElementRef;
  wndw: Window = window;

  private countryValues: Value[] = [];

  private regionValues: UserGroup[];

  @ViewChild(FiltersComponent, { static: false })
  private filterRef: FiltersComponent;

  constructor(
    private readonly router: Router,
    private readonly svcAffiliation: AffiliationAPI,
    private readonly svcValue: ValueAPI,
    private readonly svcUserGroup: UserGroupAPI,
    private readonly svcAcl: ACL,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.attachSearchHandler();

    this.filters.push(
      {
        title: 'Status',
        key: '_meta.status.keyword',
        type: FilterType.MULTI_VALUE,
        values: Object.keys(AffiliationStatus)
          .sort()
          .map((statusKey) => ({ title: AffiliationStatus[statusKey], displayValue: AffiliationStatus[statusKey] })),
      },
      {
        title: 'Created',
        key: 'createdAt',
        type: FilterType.DATE_RANGE,
      },
      {
        title: 'Verified',
        key: 'verified',
        type: FilterType.SINGLE_VALUE,
        values: [
          { title: 'true', displayValue: 'Verified' },
          { title: 'false', displayValue: 'Unverified' },
        ],
      },
      {
        title: 'Duplicate',
        key: 'duplicateSuspect',
        type: FilterType.SINGLE_VALUE,
        values: [{ title: 'true', displayValue: 'Yes' }],
      },
      {
        title: 'Ready for Delivery',
        key: 'readyForDelivery',
        type: FilterType.SINGLE_VALUE,
        values: [
          { title: 'true', displayValue: 'Yes' },
          { title: 'false', displayValue: 'No' },
        ],
      }
    );

    this.searchTerm = this.activatedRoute.snapshot.queryParamMap.get('searchTerm') || '';

    if (this.svcAcl.hasRole(Roles.ViewAutomate)) {
      this.filters = [
        ...this.filters,
        {
          title: 'Automated',
          key: 'source',
          type: FilterType.MULTI_VALUE,
          values: [
            { title: 'automated', displayValue: 'Automated' },
            { title: 'curated', displayValue: 'Curated' },
          ],
        },
      ];
    }

    forkJoin({
      types: this.svcValue.find(ValueType.AffiliationType, Number.MAX_SAFE_INTEGER),
      regions: this.svcUserGroup.find(Number.MAX_SAFE_INTEGER),
      countries: this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER),
      states: this.svcValue.find(ValueType.CountryState, Number.MAX_SAFE_INTEGER),
    }).subscribe(({ types, regions, countries, states }) => {
      const typesFilter = {
        title: 'Type',
        key: 'type',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: types.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      const regionFilter = {
        title: 'Regions',
        key: 'address.countryCode',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: regions.map((value) => ({ title: value.name, displayValue: value.name })),
      };

      const stateFilter = {
        title: 'States',
        key: 'address.opState',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: states.reduce((acc, value) => {
          acc.push(...value.value.map((v) => ({ title: v, displayValue: v })));
          return acc;
        }, []),
      };

      // No city filter - nobody ever used that

      // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)
      this.filters = [...this.filters, typesFilter, regionFilter, stateFilter];

      this.countryValues = countries;
      this.regionValues = regions;

      this.filterBarConfig = Object.assign({}, this.filterBarConfig, {
        type: {
          label: 'Type',
          format: (value) => {
            return (types.find((o) => o.code === value) || { title: '' }).title;
          },
        },
      });

      this.advancedSearchSpec = JSON.parse(this.wndw.localStorage.getItem(LS_ADVANCED_SEARCH_KEY) || 'null');
      if (this.advancedSearchSpec) {
        // restore sort key & page as well
        if (this.advancedSearchSpec.sort) {
          const sortPrefix = this.advancedSearchSpec.sortDesc ? '-' : '+';
          this.sort = sortPrefix + this.advancedSearchSpec.sort;
        }
        if (this.advancedSearchSpec.page > 0) {
          this.pagingPage = this.advancedSearchSpec.page + 1;
          this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
        }
        // and execute the search
        this.runAdvancedSearch();
      } else {
        // else getPeople is called from the template
        this.doLoad(); // We rely on countries to map code to country name
      }
    });
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  attachSearchHandler(): void {
    this.searchCtrl.valueChanges.pipe(distinctUntilChanged(), debounceTime(400)).subscribe((val) => {
      // search field gets fired with `undefined` value after init
      if (typeof val !== 'undefined') {
        this.doLoad();
      }
    });
  }

  getAffiliations(): void {
    if (this.advancedSearchSpec) {
      this.runAdvancedSearch();
      return;
    } // (else do the default)

    this.isLoading = true;

    if (this.sort === '-_id' && this.searchTerm) {
      // Remove sorting by id when term is not empty
      this.sort = '';
    } else if (!this.sort && !this.searchTerm) {
      // Revert sorting by id when term is empty
      this.sort = '-_id';
    }

    this.svcAffiliation
      .find(
        this.searchTerm,
        this.pagingLimit,
        this.pagingSkip,
        this.sort,
        this.normalizedFilter,
        true,
        undefined,
        false
      )
      .subscribe(
        (resp) => {
          this.affiliations = resp;
          this.affiliations.forEach((affiliation) => {
            if (affiliation.departments) {
              affiliation.departments.sort((a, b) => {
                const depA = (a.department || '').toLowerCase().trim(); // ignore case
                const depB = (b.department || '').toLowerCase().trim(); // ignore case

                // always sort alphabetically - if we wanted those w/ search term up, we should mark that visually (so far, we don't)
                if (depA < depB) {
                  return -1;
                }
                if (depA > depB) {
                  return 1;
                }

                if (!depA && depB) {
                  // brings the primary department up
                  return -1;
                }
                return 0;
              });
            }
          });
        },
        null,
        () => (this.isLoading = false)
      );
  }

  getCount() {
    delete this.total;

    this.svcAffiliation
      .count(this.searchTerm, this.normalizedFilter, undefined, false)
      .subscribe((res) => (this.total = res));
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAffiliations();
  }

  doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getAffiliations();
    this.getCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getAffiliations();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.normalizedFilter = cloneDeep(filter);

    if (Array.isArray(this.normalizedFilter['address.countryCode'])) {
      this.normalizedFilter['address.countryCode'] = this.normalizedFilter['address.countryCode'].reduce((acc, v) => {
        const res = this.regionValues.find((r) => r.name === v);
        if (res) {
          acc.push(...res.countries);
        }
        return acc;
      }, []);
    }

    this.doLoad();
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  async onDelete(id: string, $event): Promise<void> {
    $event.stopPropagation();

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    const res = await firstValueFrom(this.svcAffiliation.deleteById(id));
    if (res?.connectionCount > 0) {
      if (
        !window.confirm(
          `This entry is connected to ${res.connectionCount} resources. Delete requests will be sent for every connections. Are you sure you want to proceed?`
        )
      ) {
        return;
      }

      await firstValueFrom(this.svcAffiliation.deleteById(id, true));
    }

    this.getAffiliations();
    this.getCount();
  }

  navigateTo(route) {
    this.router.navigate(route);
  }

  public onExport(): void {
    this.svcAffiliation.downloadAffiliationsFile(this.normalizedFilter, this.searchTerm).subscribe((blob) => {
      const dlDummyLink = document.createElement('a');

      dlDummyLink.setAttribute('download', `affiliation_list_${new Date().toLocaleString()}.xlsx`);
      dlDummyLink.setAttribute('href', URL.createObjectURL(blob));
      dlDummyLink.click();

      dlDummyLink.parentElement.removeChild(dlDummyLink);
    });
  }

  onCopyID(id: string, $event: Event) {
    $event && $event.stopPropagation();
    navigator.clipboard.writeText(id);
  }

  openAdvancedSearch() {
    this.advancedSearchSpec = JSON.parse(this.wndw.localStorage.getItem(LS_ADVANCED_SEARCH_KEY) || '{"filter": {}}');
    this.advancedSearchDialogElement.nativeElement.show();
  }

  runAdvancedSearch() {
    if (!this.advancedSearchSpec) {
      return;
    }
    if (Object.values(this.advancedSearchSpec.filter || {}).filter((f) => !isNil(f)).length < 1) {
      return; // we don't have a single filter
    }
    this.advancedSearchDialogElement?.nativeElement?.close();
    // take over search & page & sort
    this.advancedSearchSpec.size = this.pagingLimit;
    this.advancedSearchSpec.page = this.pagingPage - 1;

    const sortMatch = /^([\+|-]?)(.+)$/.exec(this.sort || '');
    if (sortMatch) {
      this.advancedSearchSpec.sortDesc = '+' !== sortMatch[1];
      this.advancedSearchSpec.sort = sortMatch[2];
    }

    // persist to localstorage
    localStorage.setItem(LS_ADVANCED_SEARCH_KEY, JSON.stringify(this.advancedSearchSpec));
    // actually pull results
    this.isLoading = true;
    this.svcAffiliation
      .advancedSearch(this.advancedSearchSpec)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.affiliations = res.items;
          this.total = { count: res.total };
        },
        () => (this.isLoading = false)
      );
  }

  clearAdvancedSearch() {
    this.advancedSearchDialogElement.nativeElement.close();
    this.advancedSearchSpec = null;
    this.wndw.localStorage.removeItem(LS_ADVANCED_SEARCH_KEY);
    this.sort = '-_id'; // clear sort when clearing search
    this.doLoad();
  }
}
